import { useState, useEffect, useRef } from "react";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import Box from "@mui/material/Box";
// actions
import { exploreActionCreators } from "../../pages/Explore/exploreModule";
// hooks
import { useActions } from "../../common/hooks/useActions";
// helpers
import i18n from "../../common/utils/translation/i18n";
import { isFilterOffersActive } from "../../pages/Explore/helpers/isFilterOffersActive";
import { navigateFromNotFoundPage } from "../../common/utils/helpers/navigateFromNotFoundPage";
import { openLinkInNewTab } from "../../common/utils/helpers/openLinkInNewTab";
import { axiosInstance } from "../../common/utils/apiHelper";
import { getMenuLinkIcons } from "../../common/utils/helpers/getMenuLinkIcons";
import { supportedLanguages } from "../../common/utils/translation/languagesConfig";
// icons
import { exploreIcon, languageIcon, arrowUpLight } from "../../common/vector";
// component
import AppHamburgerMenu from "../AppHamburgerMenu";

interface AppHeaderProps {
  logo: string;
  color: number;
  showExploreIcon?: boolean;
  showLanguageIcon?: boolean;
  handleExploreIconClick?: () => void;
  menuLinks?: Array<any>;
}

export default function AppHeader({
  logo,
  color,
  showExploreIcon,
  showLanguageIcon,
  handleExploreIconClick,
  menuLinks
}: AppHeaderProps) {
  const { language, code } = useParams<{ language: string; code: string }>();

  const navigate = useNavigate();
  const location = useLocation();
  const languageMenuRef = useRef<HTMLDivElement>(null);
  const languageDefault = localStorage.getItem("i18nextLng");

  // local state
  const [isOpen, setIsOpen] = useState(false);
  const [isLanguageMenuOpen, setIsLanguageMenuOpen] = useState(false);
  const [selectedLanguage, setSelectedLanguage] = useState(languageDefault);

  // actions
  const resetFilterOffers = useActions(exploreActionCreators?.resetFilterOffersAction, []);

  // states
  const selectedOfferFromSearch = useSelector((state: any) => state.explore.selectedOfferFromSearch);
  const defaultDomain = useSelector((state: any) => state.global.defaultDomain);
  const isLanguageMenuDisabled = useSelector((state: any) => state.global.isLanguageMenuDisabled);

  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
  }, [isOpen]);

  useEffect(() => {
    languageMenuRef && document.addEventListener("click", handleOutsideClick);

    return () => {
      document.removeEventListener("click", handleOutsideClick);
    };
  }, []);

  useEffect(() => {
    setSelectedLanguage(languageDefault);
  }, [languageDefault]);

  useEffect(() => {
    // Clone the axios instance config
    const newAxiosConfig = { ...axiosInstance.defaults };

    // Update the x-locale header with the selected language
    newAxiosConfig.headers["x-locale"] = selectedLanguage;

    // Update the axios instance with the new config
    axiosInstance.defaults = newAxiosConfig;
  }, [selectedLanguage]);

  const onLogoClick = () => {
    navigateFromNotFoundPage(location?.state?.goToMainDomain, defaultDomain, navigate, language);

    // if we have active offer filters (search, categories in later stage, whatever...) we should reset it when clicking on the Logo
    const isActive: boolean = isFilterOffersActive(selectedOfferFromSearch?.offer_code);
    if (isActive) {
      resetFilterOffers();
    }
  };

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const toggleLanguageMenu = () => {
    setIsLanguageMenuOpen(!isLanguageMenuOpen);
  };

  const handleLanguageChange = (language: string) => {
    setSelectedLanguage(language);
    setIsLanguageMenuOpen(false);
    localStorage.setItem("i18nextLng", language);
    i18n.changeLanguage(language);
    // Add logic to handle language change in your application
  };

  // Close the menu when clicking outside of it
  const handleOutsideClick = (event: MouseEvent) => {
    if (languageMenuRef.current && !languageMenuRef.current.contains(event.target as Node)) {
      setIsLanguageMenuOpen(false);
    }
  };

  return (
    <Box className="header" sx={{ background: color }} data-cy="guest-header">
      {menuLinks && menuLinks?.length > 0 && (
        <>
          <AppHamburgerMenu isOpen={isOpen} toggleMenu={toggleMenu} language={language} menuLinks={menuLinks} />
          <div className={`hamburger-menu-icon ${isOpen ? "open" : ""}`} onClick={toggleMenu}>
            <div className="bar"></div>
            <div className="bar"></div>
            <div className="bar"></div>
          </div>
        </>
      )}

      <div className="header__nav-container app-container">
        <div className="header__logo-box">
          <img
            className={`${menuLinks && menuLinks?.length > 0 ? "header__logo-box__img" : ""}`}
            src={logo}
            alt="logo-icon"
            onClick={onLogoClick}
          />
        </div>
        <div className="header__icons">
          {menuLinks &&
            menuLinks?.length > 0 &&
            menuLinks?.map((menu: any, i: number) => (
              <Box
                key={menu?.link}
                className={`header__icon-box ${i === menuLinks?.length - 1 ? "header__icon-box--with-divider" : ""}`}
                onClick={() => openLinkInNewTab(menu?.link)}
                display={{ xs: "none", sm: "flex" }} // hide on small screens (when burger menu is shown)
              >
                {getMenuLinkIcons(menu?.icon, { fontSize: "29px" })}
                <span>{menu?.label}</span>
              </Box>
            ))}
          {showLanguageIcon && (
            <div
              className={`header__icon-box  ${isLanguageMenuDisabled ? "language-box--disabled" : "language-box"}`}
              onClick={toggleLanguageMenu}
              ref={languageMenuRef}
            >
              <img src={languageIcon.default} alt="language-icon" />
              <span className="language-menu__label">{selectedLanguage}</span>
              <div className="language-menu__arrow-box">
                <img
                  className={`language-menu__arrow ${
                    isLanguageMenuOpen ? "language-menu__arrow--up" : "language-menu__arrow--down"
                  }`}
                  src={arrowUpLight.default}
                  alt="arrow-up"
                />
              </div>
            </div>
          )}
          <div
            className={`language-menu ${isLanguageMenuOpen ? "language-menu--open" : ""} ${
              isLanguageMenuDisabled ? "language-menu--disabled" : ""
            }`}
          >
            {supportedLanguages?.map(
              language =>
                language.abr !== selectedLanguage && (
                  <span
                    key={language.abr}
                    className="language-option"
                    onClick={() => handleLanguageChange(language.abr)}
                  >
                    {language.abr}
                  </span>
                )
            )}
          </div>

          {/* {showExploreIcon && (
            <div className="header__icon-box header__icon-box--with-divider" onClick={handleExploreIconClick}>
              <img src={exploreIcon.default} alt="explore-icon" />
              <span>Explore</span>
            </div>
          )} */}
        </div>
      </div>
    </Box>
  );
}
